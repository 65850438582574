.lockers-detail {
    height: 100%;
    width: 80%;
    margin: 0 auto;

    &-combo {
        display: flex;
        justify-content: center;
        height: 95%;
        &-character {
            min-width: 54%;
            text-align: center;
            animation: zoomIn 0.5s ease-in-out;
            &.anims {
                animation: zoomIn 0.5s ease-in-out;
            }
            img {
                width: auto;
                max-height: 100%;
            }
        }
        &-box {
            position: relative;
            background: url("../../../../assets/images/locker-detail-box.png") no-repeat;
            background-size: 100% 100%;
            text-align: center;
            min-width: 46%;
            padding: 25% 0 0;
            animation: zoomIn 0.5s ease-in-out;
            .title {
                position: absolute;
                top: 5%;
                left: 50%;
                transform: translateX(-50%);
                max-width: 75%;
            }
            .close {
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translateX(-50%);
                max-width: 20%;
                cursor: pointer;
            }
            .options {
                margin-bottom: 1rem;
                h4 {
                    font-size: 1.05vw;
                    text-transform: uppercase;
                }
                p {
                    font-size: 1.05vw;
                    line-height: 1.3;
                }
            }
        }
    }
    &-list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 93%;
        &-item {
            height: 17%;
            filter: brightness(0.5);
            transition: 0.3s ease;
            cursor: pointer;
            animation: zoomIn 0.5s ease-in-out;
            &.active, &:hover {
                filter: brightness(1);
            }
            img {
                height: 100%;
                width: auto;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .lockers-detail {
        width: 100%;
        .col-top {
            height: 80% !important;
        }
        .col-bot {
            height: 20% !important;
        }
        &-combo {
            &-box {
                .options {
                    margin-bottom: 1rem;
                    h4 {
                        font-size: 2vw;
                    }
                    p {
                        font-size: 1.7vw;
                    }
                }
            }
        }
        &-list {
            flex-direction: row;
            &-item {
                width: 15%;
                height: 100%;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .lockers-detail {
        .ant-row {
            align-items: flex-end;
        }
        .col-top {
            height: 65% !important;
        }
        .col-bot {
            height: 20% !important;
        }
        &-combo {
            padding-top: 12vw;
            &-character {
                min-width: 45%;
            }
            &-box {
                min-width: 55%;
                padding: 30% 0 0;
                .title {
                    min-width: 65%;
                }
            }
            &-box {
                .options {
                    margin-bottom: 1.5vw;
                    h4 {
                        font-size: 3vw;
                    }
                    p {
                        font-size: 2.7vw;
                    }
                }
            }
        }
        &-list {
            flex-direction: row;
            &-item {
                display: flex;
                align-items: center;
            }
        }
    }
}