.theatre {
    position: relative;
    height: 100%;
    background: url("../../assets/images/theatre-bg.jpg") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;

    h2 {
        padding-top: 3.8vh;
        font-size: 5.7vh;
        text-align: center;
        height: 9.5vh;
        animation: zoomIn 1s ease-in-out;
    }

    &-container {
        height: calc(100% - 9.5vh - 4vh);
    }
    &-title {
        height: 13%;
        display: flex;
        align-items: center;
        justify-content: center;
        h3 {
            font-size: 3.15vh;
            visibility: hidden;
            &.active {
                visibility: visible;
                animation: zoomIn 0.3s ease-in-out;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .theatre {
        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        h2 {
            padding-top: 3.8vh;
            font-size: 4.7vw;
            text-align: center;
            height: 7.5vh;
            animation: zoomIn 1s ease-in-out;
        }
    
        &-container {
            height: calc(100% - 7.5vh - 4vh);
        }
    }
}

@media only screen and (max-width: 992px) {
    .theatre {
        &-container {
            display: block;
        }
        &-title {
            h3 {
                font-size: 4vw;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .theatre {
        background: url("../../assets/images/m-theatre-bg.jpg") no-repeat;
        background-size: 100% 100%;
        h2 {
            font-size: 6.7vw;
            height: 30vw;
            padding-top: 0;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
        &-container {
            padding-top: 15%;
            height: calc(100% - 30vw);
            width: 92%;
            margin: 0 auto;
            overflow: hidden;
        }
        &-title {
            h3 {
                text-transform: uppercase;
            }
        }
    }
}