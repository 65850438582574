.explore {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    &-title {
        h2 {
            font-size: 4.2vh;
            padding-top: 0;
            animation: zoomIn 1s ease-in-out;
        }
    }
    &-points {
        animation: zoomIn 1s ease-in-out;
        p {
            font-size: 4.2vh;
            span {
                color: #ff82d4;
            }
        }
    }
    &-warn {
        text-align: center;
        width: 100%;
        p {
            font-size: 2.3vh;
            color: #fff22a;
        }
    }
    &-btn {
        animation: zoomIn 1s ease-in-out;
        img {
            cursor: pointer;
            max-height: 16vh;
            width: auto;
            object-fit: cover;
        }
    }
}

@media only screen and (max-width: 992px) {
    .explore {
        &-warn {
            p {
                font-size: 4.5vw;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .explore {
        &-title {
            margin-bottom: 1rem;
            h2 {
                font-size: 7vw;
            }
        }
        &-points {
            margin-bottom: 1rem;
            p {
                font-size: 4.7;
            }
        }
        &-badge {
            width: 70%;
            margin: 0 auto;
            margin-bottom: 1rem;
            .badge {
                max-width: unset;
                width: 95%;
                justify-content: unset;
                padding: 4% 0 4% 4%;
                &-info {
                    p {
                        white-space: nowrap;
                    }
                }
            }
        }
        &-warn {
            p {
                font-size: 5vw;
            }
        }
        &-btn {
            margin-bottom: 1rem;
            width: 70%;
            img {
                max-height: unset;
                height: auto;
                width: 100%;
            }
        }
    }
}