.login-remind {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    cursor: pointer;
    visibility: hidden;
    transition: 0.5s ease;
    opacity: 0;
    &.active {
        opacity: 1;
        visibility: visible;
    }

    display: flex;
    align-items: center;
    &-box {
        position: relative;
        width: 45%;
        height: 70%;
        margin: 0 auto;
        background: url("../../../assets/images/point-box.png");
        background-size: 100% 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            font-size: 4.2vh;
            text-align: center;
            // white-space: nowrap;
            padding-bottom: 2vh;
            width: 65%;
            span {
                color: #ff82d4;
            }
        }
        .btn {
            max-height: 11vh;
            width: auto;
        }
        .close {
            position: absolute;
            top: 2%;
            right: 2%;
            height: 23%;
            width: auto;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .login-remind {
        &-box {
            width: 60%;
            p {
                font-size: 3vw;
            }
            .btn {
                max-height: 10vh;
                width: auto;
            }
            .close {
                position: absolute;
                top: 2%;
                right: 2%;
                height: 23%;
                width: auto;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .login-remind {
        &-box {
            height: auto;
            padding: 10% 5%;
            p {
                font-size: 3vw;
            }
            .btn {
                max-height: unset;
                width: auto;
                max-width: 45%;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .login-remind {
        &-box {
            width: 80%;
            p {
                font-size: 4.7vw;
                width: 80%;
            }
            .btn {
                width: 60%;
                height: auto;
            }
        }
    }
}