.lockers {
    position: relative;
    height: 100%;
    background: url("../../assets/images/locker-bg.jpg") no-repeat;
    background-size: cover;
    background-position: top center;
    h2 {
        padding-top: 3.8vh;
        font-size: 2.7vw;
        text-align: center;
        height: 9.5vh;
        animation: zoomIn 1s ease-in-out;
        &.mob {
            display: none;
        }
    }
    &-container {
        height: calc(100% - 9.5vh - 3vh);
        max-width: 60%;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 1366px) {
    .lockers {
        &-container {
            max-width: 85%;
        }
    }
}
@media only screen and (max-width: 992px) {
    .lockers {
        background: url("../../assets/images/m-locker-bg.jpg") no-repeat;
        background-size: cover;
        background-position: top center;
        h2 {
            font-size: 4.7vw;
            height: 18vw;
            padding-top: 0;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
        &-container {
            height: calc(100% - 18vw);
            max-width: 80%;
        }
    }
}

@media only screen and (max-width: 575px) {
    .lockers {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        h2 {
            font-size: 6.7vw;
            height: 30vw;
            align-items: flex-start;
            display: none;
            &.mob {
                display: block;
                text-shadow: 2px 2px 4px #000000;
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: -20%;
                height: 20vw;
                width: 16vw;
                background: url("../../assets/images/m-locker-title-ic-1.png");
                background-size: 100% 100%;
                animation: zoomInf 3s ease infinite;
            }
            &::before {
                content: '';
                position: absolute;
                top: -5%;
                right: -17%;
                height: 22vw;
                width: 18vw;
                background: url("../../assets/images/m-locker-title-ic-2.png");
                background-size: 100% 100%;
                animation: zoomInf 3s ease infinite;
            }
        }
        &-container {
            height: calc(100% - 30vw);
            max-width: 95%;
        }
    }
}