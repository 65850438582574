.homepage {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: url("../../assets/images/home-bg.jpg") no-repeat;
    // background-size: 100% 100%;
    background-size: cover;
    background-position: center top;

    &-video {
        position: absolute;
        z-index: 10000;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 1;
        transition: 0.5s ease-in-out;

        &-vid {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.closing {
            opacity: 0;
        }
    }

    &-create {
        position: absolute;
        z-index: 9999;
        bottom: -4%;
        left: 2%;
        height: 32.4vh;
        width: auto;
        animation: zoomIn 1s ease-in-out;
        cursor: pointer;
        transition: 0.4s ease;
        &:hover {
            transform: scale(1.03);
        }
    }
    .guide {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {
    .homepage {
        background-position: 82% 30%;
        video {
            object-fit: contain;
        }
        &-create {
            bottom: -4%;
            left: 2%;
            height: 32%;
        }

        .guide {
            display: block;
            z-index: 99999;
        }
    }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
    .homepage {
        background: url("../../assets/images/home-bg-mobile.jpg") no-repeat;
        background-size: 100% 100%;
        background-position: center top;
        &.isIntro {

        }
        &-create {
            bottom: 2%;
            left: 10%;
            height: 26%;
        }
    }
}