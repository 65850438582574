.create {
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: space-evenly;
    height: 100%;
    background: url("../../assets/images/create-bg.jpg") no-repeat;
    background-size: 100% 100%;
    
    h2 {
        padding-top: 3.8vh;
        font-size: 5.7vh;
        animation: zoomIn 1s ease-in-out;
    }

    &-box {
        height: 82%;
        max-height: 56vh;
        width: 50%;
        // max-width: 960px;
        max-width: 100vh;
        background: url("../../assets/images/create-box.png") no-repeat;
        background-size: 100% 100%;
        padding: 40px 0 20px 0;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        
        &-step {
            display: flex;
            align-items: center;
            animation: zoomIn 1s ease-in-out;
            &.none {
                display: none;
            }
            &-forward {
                margin: 0 1rem;
            }
            &-item {
                height: 7vh;
                width: 7vh;
                object-fit: cover;
                opacity: 0.5;
                transition: 0.5s ease;
                &.active {
                    opacity: 1;
                }
            }
        }
    }

    
}

@media only screen and (max-width: 1600px) {
    .create {
        &-box {
            width: 60%;
        }
    }
}

@media only screen and (max-width: 992px) {
    .create {
        h2 {
            font-size: 4.7vw;
            padding-top: 2.8vh;
        }
        &-box {
            width: 100%;
            max-height: 65vh;
            &-step {
                justify-content: center;
                &-forward {
                    width: 30%;
                }
                &-item {
                    height: 7vw;
                    width: 7vw;
                }
            }
        }
    } 
}

@media only screen and (max-width: 767px) {
    .create {
        h2 {
            padding-top: 10.8vh;
        }
        &-box {
            width: 100%;
            height: 100%;
            max-height: 60vh;
            background: url("../../assets/images/m-create-box.png") no-repeat;
            background-size: 100% 100%;
            padding: 10vw 0;
        }
    }
}

@media only screen and (max-width: 575px) {
    .create {
        h2 {
            font-size: 6.7vw;
        }
        &-box {
            &-step {
                &-item {
                    height: 10vw;
                    width: 10vw;
                }
            }
        }
    }
}