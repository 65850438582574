.handbook {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    cursor: pointer;
    visibility: hidden;
    transition: 0.5s ease;
    opacity: 0;
    &.active {
        opacity: 1;
        visibility: visible;
    }

    display: flex;
    align-items: center;
    &-container {
        position: relative;
        // width: 70%;
        height: 90%;
        margin: 0 auto;

        .book {
            height: 100%;
            width: auto;
        }
        .m-book {
            display: none;
            &-nav {
                display: none;
            }
        }
        .close {
            position: absolute;
            top: -3%;
            right: -4%;
            height: 13%;
            width: auto;
        }
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {
    .handbook {
        display: flex;
        align-items: center;
        &-container {
            text-align: center;
            height: 90%;
            .book {
                height: auto;
                width: 95%;
            }
            .close {
                position: absolute;
                top: -3%;
                right: 0%;
                height: 9%;
                width: auto;
            }
        }
    }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
    .handbook {
        z-index: 99999;
        &-container {
            width: 100vw;
            max-height: 144vw;
            .book {
                display: none;
            }
            .m-book {
                position: relative;
                display: none;
                // grid-template-columns: 1fr;
                height: 100%;
                width: 97%;
                
                &-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    // grid-row-start: 1;
                    // grid-column-start: 1;
                    height: 100%;
                    width: 100%;
                    max-height: 77vh;
                    transition: transform 1s ease;
                    &.page1 {
                        z-index: 2;
                        &.active {
                            left: -100%;
                            transform: perspective(2000px) rotateY(180deg);
                        }
                    }
                    &.page2 {
                        z-index: 1;
                    }
                }


                &-nav {
                    display: block;
                    position: absolute;
                    z-index: 13;
                    top: 45%;
                    &.hide {
                        filter: brightness(0.5);
                        cursor: not-allowed;
                    }
                    &.next {
                        right: 1%;
                    }
                    &.prev {
                        left: 1%;
                    }
                    max-width: 11%;
                    width: auto;
                }
            }
            .books {
                height: 77vh;
                margin: 0 auto;
                width: 94%;
                max-height: 144vw;
            }
            .books > div {
                height: 100%;
                transform-origin: left;
                max-height: 144vw;
            }

            .books-cover {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 94%;
    
                z-index: 9;
                text-align: center;
                background: url("../../../../assets/images/m-book-1.png");
                background-size: 100% 100%;
                color: white;
                transition: transform 2s;
            }

            .books-content {
                width: 100%;
                background: url("../../../../assets/images/m-book-2.png");
                background-size: 100% 100%;
                transition: all 0.3s 1s;
            }

            .books-cover > div {
                transition: opacity 0s 0.6s;
            }

            .books.active > .books-cover {
                transform: rotateY(180deg) scale(1);
            }

            .books.active > .books-cover > div {
                opacity: 0;
            }
            .close {
                z-index: 14;
                top: unset;
                bottom: -7%;
                right: 50%;
                transform: translateX(50%)
            }
        }
    }
}