.profile-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 6%;
    height: 100%;
    &-title {
        text-align: center;
        animation: zoomIn 1s ease;
        p {
            font-size: 4.8vh;
        }
        span {
            white-space: nowrap;
            font-size: 6vh;
            padding: 0 0.5rem;
        }
    }
    &-slide {
        animation: zoomIn 1s ease;
        &.completed {
            margin-bottom: 1.5rem;
            .slick-track {
                filter: brightness(0.5);
            }
        }
        h3 {
            font-size: 4.2vh;
            padding-bottom: 1vh;
        }
        &-item {
            cursor: pointer;
        }
        .slick-slider {
            width: 80%;
            margin: 0 auto;
            img {
                // max-height: 12.2vh;
                height: auto;
                max-width: 96%;
            }
        }
        .slick-arrow {
            position: absolute;
            width: 4vh;
            height: 6vh;
            z-index: 10;
            border: none;
            cursor: pointer;
            &::before {
                display: none;
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
                -webkit-animation-name: pulseBtn;
                animation-name: pulseBtn;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                -webkit-animation-iteration-count: infinite;
            }
            &.slick-prev {
                left: -12%;
                &::after {
                    background: url("../../../../assets/images/slide-prev.png");
                    background-size: 100% 100%;
                }
            }
            &.slick-next {
                right: -12%;
                &::after {
                    background: url("../../../../assets/images/slide-next.png");
                    background-size: 100% 100%;
                }
            }
            &.slick-disabled {
                opacity: 0.5;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .profile-box {
        height: auto;
        &-title {
            margin-top: 1rem;
            p {
                font-size: 3.6vw;
                span {
                    font-size: 5vw;
                }
            }
        }
        &-slide {
            h3 {
                font-size: 2.6vw;
            }
            .slick-arrow {
                width: 5vw;
                height: 7vw;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .profile-box {
        margin-left: 0 !important;
        &-title {
            p {
                font-size: 6.6vw;
                span {
                    font-size: 8vw;
                }
            }
        }
        &-slide {
            margin-top: 3vw;
            h3 {
                font-size: 4.6vw;
            }
        }
    }
}