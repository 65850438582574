.edit-badge-box {
    height: 82%;
    max-height: 56vh;
    width: 70%;
    margin: 0 auto;
    // max-width: 960px;
    max-width: 100vh;
    background: url("../../../../assets/images/create-box.png") no-repeat;
    background-size: 100% 100%;
    padding: 40px 0 20px 0;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .btn-group {
        background: url("../../../../assets/images/finish-btn.png") no-repeat;
        background-size: 100% 100%;
        width: 28%;
    }

    .badgeAPI {
        min-width: 30vh;
    }
}

@media only screen and (max-width: 1200px) {
    .edit-badge-box {
        width: 90%;
    }
}

@media only screen and (max-width: 992px) {
    .edit-badge-box {
        .btn {
            margin: 2rem 0;
            &-group {
                width: 40%;
                img {
                    width: 40%;
                    height: auto;
                }
            }
        }
        .group {
            &-right {
                .badgeAPI {
                    padding: 4% !important;
                    width: 100%;
                    min-width: unset;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .edit-badge-box {
        width: 100%;
        background: url("../../../../assets/images/m-create-box.png") no-repeat;
        background-size: 100% 100%;
        .group {
            flex-direction: column;
            flex-flow: column-reverse;
            &-left {
                padding-right: 0;
                &::after {
                    content: none;
                }
                &-item {
                    margin-bottom: 1vw;
                }
            }
            &-right {
                .badgeAPI {
                    width: auto;
                    margin: 0 auto;
                    max-width: 75%;
                    &-info {
                        p {
                            font-size: 4vw;
                        }
                    }
                }
            }
        }
        .btn {
            &-group {
                width: 45%;
                min-height: unset;
                padding: 4%;
                img {
                    height: auto;
                }
            }
        }

    }
}
