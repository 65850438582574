@font-face {
    font-family: "Urzeit-Italic";
    src: url("../assets/fonts/Urzeit-Italic.ttf") format('truetype');
}
@font-face {
    font-family: "Urzeit-Regular";
    src: url("../assets/fonts/Urzeit-Regular.ttf") format('truetype');
}
@font-face {
    font-family: "Tungsten-Bold";
    src: url("../assets/fonts/Tungsten-Bold.ttf") format('truetype');
}
@font-face {
    font-family: "Gotham-Bold";
    src: url("../assets/fonts/Gotham-Bold.otf") format('truetype');
}
@font-face {
    font-family: "Gotham-Medium";
    src: url("../assets/fonts/Gotham Medium.otf") format('truetype');
}
@font-face {
    font-family: "GothamBook";
    src:url('../assets/fonts/Gotham-Book.woff') format('woff'),
        url('../assets/fonts/Gotham-Book.woff2') format('woff2'), 
        url("../assets/fonts/GothamBook.ttf") format('truetype');
}
@font-face {
    font-family: "UTM Helve";
    src: url("../assets/fonts/UTM Helve.ttf") format('truetype');
}