.labor-picker {
    position: relative;
    z-index: 10;
    &-item {
        max-width: 50%;
        animation: zoomIn 1s ease-in-out;
        &-image {
            transform: translateX(-10%) scale3d(0.8, 0.85, 1);
            transition: 0.3s ease;
            &.mob {
                display: none;
            }
            &.active {
                transform: scale(1);
            }
            cursor: pointer;
            max-height: 17vh;
        }
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {
    .labor-picker {
        &-item {
            max-width: 100%;
            animation: zoomIn 1s ease-in-out;
            &-image {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
    .labor-picker {
        flex-direction: row;
        &-item {
            &-image {
                display: none;
                &.mob {
                    display: block;
                    transform: translateY(11px) scale3d(0.8, 0.8, 1);
                    &.active {
                        transform: translateY(11px) scale3d(0.95, 0.95, 1);
                    }
                }
            }
        }
    }
}