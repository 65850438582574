.lockers-list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95%;
    transform: translateX(12%);
    &-icon {
        position: absolute;
        animation: zoomInf 3s ease infinite;
        @keyframes zoomInf {
            0% {
                transform: scale(1)
            }
            50% {
                transform: scale(1.1);
            }
            100% {
                transform: scale(1);
            }
        }
        &.left {
            bottom: 12%;
            left: -6%;
            max-height: 21%;
        }
        &.right {
            animation-delay: 1s;
            bottom: 5%;
            max-height: 27%;
            right: 17%;
        }
    }
    &-item {
        position: relative;
        display: flex;
        align-items: flex-end;
        height: 100%;
        filter: brightness(0.5);
        cursor: pointer;
        transition: 0.5s ease;
        animation: fadeInLocker 1s ease;
        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: -3%;
            left: 50%;
            transform: translateX(-50%);
            height: 4vh;
            width: 100%;
            background: url("../../../../assets/images/locker-doll-shadow.png") no-repeat;
            background-size: 100% 100%;
        }
        &.fade {
            visibility: hidden;
            animation: fadeLocker 1s ease-in-out;
        }
        &.frankie {
            z-index: 8;
            &.move {
                transform: translateX(20%);
                opacity: 0;
            }
        }
        &.lagoona {
            z-index: 7;
            transform: translateX(-20%);
            &.move {
                transform: translateX(-70%);
                opacity: 0;
            }
        }
        &.clawdeen {
            z-index: 6;
            transform: translateX(-50%);
            &.move {
                transform: translateX(-150%);
                opacity: 0;
            }
            img {
                height: 103%;
            }
        }
        &.draculaura {
            z-index: 7;
            transform: translateX(-106%);
            &.move {
                transform: translateX(-320%);
                opacity: 0;
            }
            img {
                height: 94%;
            }
        }
        &.cleo {
            z-index: 6;
            transform: translateX(-149%);
            &.move {
                transform: translateX(-420%);
                opacity: 0;
            }
        }
        &:hover {
            filter: brightness(1);
        }
        img {
            position: relative;
            z-index: 2;
            height: 100%;
            width: auto;
        }
    }
}

@keyframes fadeLocker {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeInLocker {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 1200px) {
    .lockers-list {
        height: 95%;
        transform: translateX(13vw);
        &-icon {
            top: 0;
            &.left {
                bottom: unset;
                left: -10%;
                max-height: 15%;
            }
            &.right {
                bottom: unset;
                max-height: 17%;
                right: 17%;
            }
        }
        &-item {
            height: 50vw;
            &::after {
                height: 10%;
            }
        }
    }    
}

@media only screen and (max-width: 575px) {
    .lockers-list {
        height: 85%;
        transform: translateX(18vw);
        align-items: flex-end;
        &-icon {
            top: 25%;
            &.left {
                bottom: unset;
                left: 5%;
                max-height: 15%;
            }
            &.right {
                bottom: unset;
                max-height: 17%;
                right: 45%;
            }
        }
        &-item {
            height: 60vw;
            &::after {
                height: 10%;
            }
        }
    }    
}