.home-dolls {
    height: 60%;
    position: relative;
    z-index: 9;
    display: flex;
    &-btn {
        position: absolute;
        max-height: 12.5vh;
        width: auto;
        object-fit: cover;
        cursor: pointer;
        animation: zoomIn 1s ease-in-out;
        transition: 0.4s ease;
        &:hover {
            transform: scale(1.03);
        }
        &.labo {
            bottom: 38%;
            left: 2%;
        }
        &.theat {
            bottom: 18%;
            left: 16%;
        }
        &.locker {
            top: 5%;
            right: 23.5%;
        }
    }
    &-group {
        position: absolute;
        bottom: -12%;
        right: 15%;
        max-height: 80%;
        width: auto;
        object-fit: cover;
        animation: zoomIn 1s ease-in-out;
    }
}
.home-mbtn {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .home-dolls {
        &-btn {
            max-height: auto;
            width: 15%;
            height: auto;
        }
        &-group {
            right: 10%;
        }
    }
}

@media only screen and (max-width: 992px) {
    .home-dolls {
        height: 70%;
        &-btn {
            max-height: auto;
            width: 28%;
            height: auto;
            &.labo {
                bottom: unset;
                top: 5%;
                left: 5%;
            }
            &.theat {
                bottom: unset;
                top: 5%;
                left: 36%;
            }
            &.locker {
                bottom: unset;
                top: 5%;
                right: 5%;
            }
        }
        &-group {
            bottom: 7%;
            right: 6%;
            max-height: 63%;
        }
    }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
    .home-dolls {
        height: 40%;
        &-btn {
            max-height: auto;
            width: 35%;
            height: auto;
            display: none;
            &.labo {
                bottom: 0;
                top: unset;
                left: 15%;
            }
            &.theat {
                bottom: 0;
                top: unset;
                left: unset;
                left: 50%;
            }
            &.locker {
                bottom: 13%;
                top: unset;
                right: 33%;
            }
        }
        &-group {
            bottom: -5%;
            right: unset;
            left: 4%;
            max-height: 80%;
            height: auto;
            width: 96%;
            object-fit: contain;
        }
    }
    .home-mbtn {
        position: relative;
        z-index: 7;
        display: block;
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-row {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-btn {
            width: 45%;
        }
    }
}