.loginpage {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    // justify-content: space-evenly;
    height: 100%;
    background: url("../../assets/images/create-bg.jpg") no-repeat;
    background-size: 100% 100%;

    h2 {
        padding-top: 3.8vh;
        font-size: 5.7vh;
        animation: zoomIn 1s ease-in-out;
    }

    &-box {
        height: 82%;
        max-height: 56vh;
        width: 50%;
        max-width: 100vh;
        background: url("../../assets/images/create-box.png") no-repeat;
        background-size: 100% 100%;
        padding: 40px 0 20px 0;
        
        &-step {
            display: flex;
            align-items: center;
            animation: zoomIn 1s ease-in-out;
            &.none {
                display: none;
            }
            &-forward {
                margin: 0 1rem;
            }
            &-item {
                height: 7vh;
                width: 7vh;
                object-fit: cover;
                opacity: 0.5;
                transition: 0.5s ease;
                &.active {
                    opacity: 1;
                }
            }
        }

        &-sub {
            width: 100%;
            text-align: center;
            p {
                font-size: 3.15vh;
            }
        }
        
        .form {
            justify-content: space-evenly;
            height: 100%;
            .ant-select-selection {
                &-item, &-placeholder {
                    text-align: left;
                }
            }
            .group-top {
                min-width: 70%;
                margin: 0 auto;
            }
            &-confirm {
                &-btn {
                    width: 28%;
                }
            }
            &-input {
                margin: 0 auto;
                justify-content: flex-start;
                &-label {
                    min-width: 14.5vh;
                    &.serial {
                        min-width: unset;
                        margin: 0 1vh !important;
                        font-size: 6vh;
                    }
                }
                &-select {
                    &.error {
                        .ant-select-selector {
                            border: 3px solid #f23434 !important;
                        }
                        .ant-select-selection-item {
                            color: #f23434;
                        }
                    }
                }
                &-serial {
                    margin: 0 0.4vh;
                    &:first-child {
                        margin-left: 0;
                    }
                    &.error {
                        input {
                            border: 3px solid #f23434;
                            color: #f23434;
                            width: calc(3.2vh - 6px) !important;
                            height: calc(5.2vh - 6px);
                        }
                    }
                    input {
                        width: 3.2vh !important;
                        height: 5.2vh;
                        padding: 0;
                        min-width: unset;
                        border-radius: 12%;
                        outline: none;
                    }
                }
            }
        }

        &-line {
            position: relative;
            width: 100%;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                height: 1px;
                width:35%;
                background: #544d62;
                transform: translate(-50%, -50%);
            }
        }

        &-create {
            background: none;
            border: none;
            width: 100%;
            max-height: 11vh;
            text-align: center;
            img {
                height: 100%;
                width: auto;
                object-fit: cover;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 1366px) {
    .loginpage {
        &-box {
            width: 65%;
        }
    }
}

@media only screen and (max-width: 992px) {
    .loginpage {
        h2 {
            font-size: 4.2vw;
            padding-top: 0.8vh;
        }
        &-box {
            width: 95%;
            max-height: 60vh;
            background: url("../../assets/images/m-create-box.png") no-repeat;
            background-size: 100% 100%;
        
            .form {
                &-input {
                    margin: unset;
                    margin-right: auto;
                    &.select {
                        flex-direction: column;
                        align-items: stretch;
                    }
                    &.code {
                        flex-direction: column;
                        align-items: stretch;
                    }
                    &-label {
                        font-size: 4vw;
                        width: 100%;
                        min-width: 100%;
                        text-align: left;
                        padding-bottom: 2vw;
                    }
                }
                &-confirm {
                    &-btn {
                        width: 30%;
                        min-height: unset;
                        padding: 4%;
                        img {
                            height: auto;
                            width: 35%;
                        }
                    }
                }
            }
            &-sub {
                p {
                    font-size: 4.5vw;
                }
            }
            &-create {
                max-width: 60%;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
} 
@media only screen and (max-width: 575px) {
    .loginpage {
        h2 {
            padding-top: 14vw;
            font-size: 6.7vw;
        }
        &-box {
            width: 95%;
            max-height: 56vh;
        }
    }
}