@import "./sass/index.scss";

#root {
  min-height: 100vh;
//   overflow: hidden;
}

body {
  margin: 0;
}

p,
span,
a, h1, h2, h3, h4, h5, h6 {
  margin: 0 !important;
  color: $text-white;
  font-weight: normal;
}

.ui {
  font-family: "Urzeit-Italic";
}
.ur {
  font-family: "Urzeit-Regular";
}
.tb {
  font-family: "Tungsten-Bold";
}
.g-bold {
  font-family: "Gotham-Bold";
}
.g-m {
  font-family: "Gotham-Medium";
}
.g-book {
  font-family: "GothamBook";
}
.utm {
  font-family: "UTM Helve";
}

[data-showinview="false"] {
  visibility: hidden;
  animation: none;
}

[data-showinview="true"] {
  visibility: visible;
}

.ant-spin-dot-item {
  background-color: #fff !important;
}

@keyframes zoomIn {
  0% {
      opacity: 0;
      transform: scale(0.9);
  }
  100% {
      opacity: 1;
      transform: scale(1);
  }
}

@keyframes pulseBtn {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}