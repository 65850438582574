.profile-flag {
    height: 100%;
    background: url("../../../../assets/images/profile-flag-bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    padding: 0 9%;

    &-title {
        animation: zoomIn 1s ease;
        text-align: center;
        h4 {
            font-size: 1.6vw;
        }
        h3 {
            font-size: 1.95vw;
        }
    }
    &-badge {
        width: 100%;
        animation: zoomIn 1s ease;
        &-title {
            display: none;
        }
    }
    &-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &-btn {
        animation: zoomIn 1s ease;
        img {
            max-width: 100%;
            cursor: pointer;
        }
    }
    &-line {
        width: 100%;
        height: 3px;
        background: #fdfcff;
        margin: 0.5vw 0;
    }
    &-signout {
        min-height: 9vh;
        width: 72%;
        margin: 0 auto;
        background: url("../../../../assets/images/btn-form.png") no-repeat;
        background-size: 100% 100%;
        
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        h3 {
            font-size: 3.15vh;
            padding-bottom: 0.6vh;
        }
    }
}

@media only screen and (max-width: 992px) {
    .profile-flag {
        height: auto;
        background: url("../../../../assets/images/point-box.png") no-repeat;
        background-size: 100% 100%;
        width: 85%;
        margin: 0 auto;
        margin-bottom: 1rem;
        padding: 8% 9%;
        justify-content: space-between;
        // min-height: 30vw;
        &-title {
            display: none;
        }
        &-badge {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            &-title {
                display: block;
                animation: zoomIn 1s ease;
                text-align: left;
                width: 100%;
                margin-left: 1rem;
                h4 {
                    font-size: 2.6vw;
                }
                h3 {
                    font-size: 2.95vw;
                }
            }
        }
        &-group {
            flex-direction: row;
            height: 7vw;
            width: 100%;
        }
        &-btn, &-signout {
            height: 100%;
            min-height: unset;
            width: auto;
            img {
                height: 100%;
                width: auto;
            }
            h3 {
                font-size: 2.5vw;
                padding-bottom: 0;
            }
        }
        &-signout {
            width: 40%;
            margin: unset;
        }
        &-line {
            display: none;
        }
    }
}

@media only screen and (max-width: 575px) {
    .profile-flag {
        height: auto;
        min-height: 30vw;
        background: url("../../../../assets/images/m-profile-flag-bg.png") no-repeat;
        background-size: 100% 100%;
        width: 91%;
        padding: 6% 4%;
        justify-content: space-evenly;
        &-title {
            display: none;
        }
        &-badge {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            &-title {
                display: block;
                animation: zoomIn 1s ease;
                text-align: left;
                width: 50%;
                margin-left: 1rem;
                h4 {
                    font-size: 3vw;
                }
                h3 {
                    font-size: 4.6vw;
                    text-shadow: 2px 2px 4px #000000;
                }
            }
            .badgeAPI {
                width: 50%;
            }
        }
        &-group {
            height: 12vw;
        }
        &-btn {
            width: 65%;
            img {
                width: 100%;
                height: auto;
            }
        }
        &-signout {
            width: 35%;
            min-height: 100%;
            h3 {
                font-size: 4.5vw;
                padding-bottom: 1vw;
            }
        }
        &-line {
            display: none;
        }
    }
}