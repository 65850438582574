.labor-game {
    height: 100%;
    &-frame {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        animation: zoomIn 1s ease-in-out;
        &-container {
            background: url('../../../../assets/images/game-frame.png') no-repeat;
            background-size: 100% 100%;
            height: 57vh;
            width: 38vh;
            padding: 5.1vh 5.1vh;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
            .test {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                // z-index: 999999;
            }
            iframe {
                height: 100%;
                width: 100%;
                border: none;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {
    .labor-game {
        &-frame {
            &-container {
                background: url('../../../../assets/images/game-frame.png') no-repeat;
                background-size: 100% 100%;
                height: 56vh;
                padding: 10.1% 10.4%;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
                iframe {
                    height: 100%;
                    width: 100%;
                    border: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
    .labor-game {
        &-frame {
            &-container {
                position: relative;
                z-index: 10;
                height: 105vw;
                padding: 9vw 8.8vw 9vw 8.8vw;
                width: 70vw;
                max-height: 55vh;
            }
        }
    }
}