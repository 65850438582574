.theatre-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 87%;
    animation: zoomIn 1s ease;

    .slick-slider {
        height: 100%;
    }

    .slick-slide {
        height: auto !important;
        filter: brightness(0.5);
        > div {
            height: 100%;
        }
        &.slick-center {
            filter: brightness(1);
            .theatre-slide-item-container {
                transform: scale3d(1, 1, 1);
            }
        }
    }

    .slick-track {
        height: 100%;
        display: flex !important;
    }

    .slick-list {
        z-index: 9;
        max-height: 100%;
    }

    .slick-arrow {
        position: absolute;
        z-index: 10;
        // top: 35%;
        top: 50%;
        // left: 0;
        // height: 15vh;
        height: 100%;
        width: 20%;
        // width: 9vh;
        border: none;
        cursor: pointer;
        &::before {
            display: none;
        }
        &::after {
            content: '';
            position: absolute;
            top: 35%;
            width: 9vh;
            height: 15vh;
            -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-name: pulseBtn;
            animation-name: pulseBtn;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            -webkit-animation-iteration-count: infinite;
        }
        &.slick-prev {
            // left: 18%;
            left: 0;
            &::after {
                right: -12%;
                background: url("../../../../assets/images/slide-prev.png");
                background-size: 100% 100%;
            }
        }
        &.slick-next {
            right: 0;
            &::after {
                left: -12%;
                background: url("../../../../assets/images/slide-next.png");
                background-size: 100% 100%;
            }
        }
    }

    &-item {
        display: flex !important;
        align-items: center;
        height: 100%;
        min-width: 60%;
        &-container {
            // padding: 2vh 20vh;
            width: 82%;
            transform: scale3d(0.8, 0.8, 0.8);
            transition: none;
            margin: 0 auto;
            &-border {
                background: url("../../../../assets/images/video-frame.png") no-repeat;
                background-size: 100% 100%;
                padding: 1.2% 1.2% 0.7% 1.2%;
                height: 95%;
            }
            img, video {
                width: 100%;
                height: 100%;
                max-height: 50vh;
                object-fit: contain;
                cursor: pointer;
                object-position: center;
                background: #000;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .theatre-slide {
        height: 65%;
        .slick-arrow {
            &::after {
                width: 30%;
                height: 33%;
            }
        }
    }
}

.theatre-mslide {
    display: none;
}

@media only screen and (max-width: 992px) {
    .theatre-mslide {
        display: block;
        width: 78%;
        margin-left: 11% !important;
        padding-top: 18%;
        &-item {
            &-image {
                img {
                    background: url("../../../../assets/images/video-frame.png") no-repeat;
                    background-size: 100% 100%;
                    padding: 1.6%;
                    width: 92%;
                    margin-left: auto;
                    height: auto;
                }
            }
            &-title {
                max-width: 70%;
                margin: 0 auto;
                p {
                    font-size: 2vw;
                    text-align: center;
                    padding-top: 2vw;
                    text-transform: uppercase;
                }
            }
        }
        .slick-slide {
            filter: unset;
            opacity: 0.5;
            &.slick-current {
                opacity: 1;
            }
        }
    }
    .theatre-slide {
        height: 45vw;
        &-item {
            &-container {
                transform: scale3d(1,1,1);
                width: 72%;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .theatre-mslide {
        display: block;
        width: 110%;
        margin-left: -1% !important;
        padding-top: 18%;
        &-item {
            &-image {
                img {
                    background: url("../../../../assets/images/video-frame.png") no-repeat;
                    background-size: 100% 100%;
                    padding: 1.6%;
                    width: 92%;
                    margin-left: auto;
                    height: auto;
                }
            }
            &-title {
                max-width: 70%;
                margin: 0 auto;
                p {
                    font-size: 2.7vw;
                    text-align: center;
                    padding-top: 2vw;
                    text-transform: uppercase;
                }
            }
        }
        .slick-slide {
            filter: unset;
            opacity: 0.5;
            &.slick-current {
                opacity: 1;
            }
        }
    }
    .theatre-slide {
        height: 54vw;
        &-item {
            &-container {
                transform: scale3d(1,1,1);
                width: 100%;
                &-border {
                    padding: 1.2% 1.2% 0% 1.2%;
                }
            }
        }
    }
}