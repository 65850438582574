.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5vh;
    max-width: 285px;
    width: auto;
    height: 10vh;
    background-size: 100% 100% !important;
    animation: zoomIn 1s ease-in-out;
    &.purple {
        background: url("../../../assets/images/badge/1.png");
    }
    &.pink {
        background: url("../../../assets/images/badge/2.png");
    }
    &.yellow {
        background: url("../../../assets/images/badge/3.png");
    }
    &.green {
        background: url("../../../assets/images/badge/4.png");
    }
    &.blue {
        background: url("../../../assets/images/badge/5.png");
    }
    &-avatar {
        width: 7vh;
        height: 7vh;
        object-fit: cover;
        margin-right: 0.5rem;
        background-size: 100% 100% !important;

        &.square {
            &.one {
                background: url("../../../assets/images/rect/1.png");
            }
            &.two {
                background: url("../../../assets/images/rect/2.png");
            }
            &.three {
                background: url("../../../assets/images/rect/3.png");
            }
            &.four {
                background: url("../../../assets/images/rect/4.png");
            }
            &.five {
                background: url("../../../assets/images/rect/5.png");
            }
        }
        &.border {
            &.one {
                background: url("../../../assets/images/round/1.png");
            }
            &.two {
                background: url("../../../assets/images/round/2.png");
            }
            &.three {
                background: url("../../../assets/images/round/3.png");
            }
            &.four {
                background: url("../../../assets/images/round/4.png");
            }
            &.five {
                background: url("../../../assets/images/round/5.png");
            }
        }
        &.circle {
            &.one {
                background: url("../../../assets/images/circle/1.png");
            }
            &.two {
                background: url("../../../assets/images/circle/2.png");
            }
            &.three {
                background: url("../../../assets/images/circle/3.png");
            }
            &.four {
                background: url("../../../assets/images/circle/4.png");
            }
            &.five {
                background: url("../../../assets/images/circle/5.png");
            }
        }
    }
    &-info {
        p {
            font-size: 2.4vh;
            text-shadow: 0 0 8px #000;
        }
    }
}