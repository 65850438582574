.btn {
    background: none;
    border: none;
    width: 100%;
    animation: zoomIn 1s ease-in-out;
    &-group {
        min-height: 9vh;
        width: 52%;
        margin: 0 auto;
        background: url("../../../../assets/images/step-2-btn.png") no-repeat;
        background-size: 100% 100%;
        
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            width: auto;
            height: 3.5vh;
            padding-bottom: 8px;
        }
    }
    // img {
    //     cursor: pointer;
    //     max-height: 10vh;
    //     width: auto;
    //     object-fit: cover;
    // }
}
.group {
    display: flex;
    align-items: center;
    // width: 100%;
    &-left {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 4vh;
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 98%;
            width: 4px;
            background: #fff;
            border-radius: 4px;
        }
        &-item {
            margin-bottom: 2vh;
            &:last-child {
                margin-bottom: 0;
            }
            &-title {
                margin-bottom: 1vh;
                animation: zoomIn 1s ease-in-out;
                p {
                    font-size: 2.8vh;
                }
            }
            &-options {
                &.border {
                    img {
                        border-radius: 8px;
                    }
                }
                &.circle {
                    img {
                        border-radius: 50%;
                    }
                }
                img {
                    margin: 0 1.4vh;
                    cursor: pointer;
                    transition: 0.3s ease;
                    filter: brightness(0.5);
                    max-height: 5vh;
                    animation: zoomIn 1s ease-in-out;
                    &.active {
                        filter: brightness(1);
                        box-shadow: 0 0 1.3vh #fff;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    &.border {
                        border-radius: 14px;
                    }
                    &.circle {
                        border-radius: 50%;
                    }
                }
            }
        }
    }
    &-right {
        padding-left: 4vh;
        &-badge {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 1.5vh;
            max-width: 285px;
            width: auto;
            height: 10vh;
            background-size: 100% 100% !important;

            &-avatar {
                width: 7vh;
                height: 7vh;
                object-fit: cover;
                margin-right: 0.5rem;
                background-size: 100% 100% !important;
            }
            &-info {
                p {
                    font-size: 2.4vh;
                    text-shadow: 0 0 8px #000;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .group {
        flex-direction: column;
        flex-flow: column-reverse;
        &-left {
            padding-right: 0;
            &::after {
                content: none;
            }
            &-item {
                margin-bottom: 1vw;
            }
        }
        &-right {
            width: 100%;
            padding-left: 0;
            margin: 2vw 0;
            .badge {
                justify-content: unset;
                max-width: 100%;
                height: 8vh;
                margin: 0 auto;
                padding: 4%;
            }
        }
    }
    .btn {
        &-group {
            width: 50%;
            min-height: 7vh;
            margin-bottom: 2vw;
            img {
                height: auto;
                width: 80%;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .btn {
        &-group {
            width: 70%;
            min-height: 7vh;
            margin-bottom: 0;
        }
    }
}