.badgeAPI {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    min-height: 10vh;
    background-size: 100% 100% !important;
    animation: zoomIn 1s ease-in-out;
    &.purple {
        background: url("../../../assets/images/badge/1.png");
    }
    &.pink {
        background: url("../../../assets/images/badge/2.png");
    }
    &.yellow {
        background: url("../../../assets/images/badge/3.png");
    }
    &.green {
        background: url("../../../assets/images/badge/4.png");
    }
    &.blue {
        background: url("../../../assets/images/badge/5.png");
    }
    &-avatar {
        width: 7vh;
        height: 7vh;
        object-fit: cover;
        margin: 0 0.5rem 0 1rem;
        background-size: 100% 100% !important;

        &.rect {
            &.one {
                background: url("../../../assets/images/rect/1.png");
            }
            &.two {
                background: url("../../../assets/images/rect/2.png");
            }
            &.three {
                background: url("../../../assets/images/rect/3.png");
            }
            &.four {
                background: url("../../../assets/images/rect/4.png");
            }
            &.five {
                background: url("../../../assets/images/rect/5.png");
            }
        }
        &.round {
            &.one {
                background: url("../../../assets/images/round/1.png");
            }
            &.two {
                background: url("../../../assets/images/round/2.png");
            }
            &.three {
                background: url("../../../assets/images/round/3.png");
            }
            &.four {
                background: url("../../../assets/images/round/4.png");
            }
            &.five {
                background: url("../../../assets/images/round/5.png");
            }
        }
        &.circle {
            &.one {
                background: url("../../../assets/images/circle/1.png");
            }
            &.two {
                background: url("../../../assets/images/circle/2.png");
            }
            &.three {
                background: url("../../../assets/images/circle/3.png");
            }
            &.four {
                background: url("../../../assets/images/circle/4.png");
            }
            &.five {
                background: url("../../../assets/images/circle/5.png");
            }
        }
    }
    &-info {
        p {
            font-size: 2.4vh;
            text-shadow: 0 0 8px #000;
        }
    }
}

@media only screen and (max-width: 992px) {
    .badgeAPI {
        min-height: unset;
        &-info {
            p {
                font-size: 2.2vw;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .badgeAPI {
        padding: 2% 3% !important;
        width: auto;
        &-avatar {
            height: 10vw;
            width: 10vw;
            min-width: 10vw;
            margin: 0 0.5rem 0 0;
        }
        &-info {
            p {
                // white-space: nowrap;
                font-size: 4vw;
            }
        }
    }
}