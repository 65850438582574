.profile {
    position: relative;
    height: 100%;
    background: url("../../assets/images/theatre-bg.jpg") no-repeat;
    background-size: 100% 100%;
    &-edit {
        &-title {
            position: absolute;
            top: 6vh;
            left: 50%;
            transform: translateX(-50%);
            h2 {
                font-size: 5.7vh;
            }
        }
    }
    &-container {
        // width: 1570px;
        max-width: 82%;
        height: calc(100% - 14.5vh - 3vh);
        padding: 14.5vh 0 3vh 0;
        margin: 0 auto;
    }
    &-box {
        // height: 100%;
        margin-left: 1rem;
        background: url("../../assets/images/profile-box-bg.png") no-repeat;
        background-size: 100% 100%;
    }
}

@media only screen and (max-width: 992px) {
    .profile {
        &-container {
            height: calc(100% - 13vw - 1vw);
            padding: 13vw 0 1vw 0;
            .ant-row {
                height: auto !important;
            }
            .ant-col {
                height: auto !important;
            }
        }
        &-edit {
            &-title {
                h2 {
                    font-size: 4.7vw;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .profile {
        background-size: cover;
        background-position: center;
        &-edit {
            &-title {
                top: 10vh;
                h2 {
                    font-size: 5.7vw;
                    white-space: nowrap;
                }
            }
        }
        &-container {
            max-width: 95%;
            height: calc(100% - 24vw - 1vw);
            padding: 24vw 0 1vw 0;
        }
    }
}