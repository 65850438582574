.guide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    cursor: pointer;
    visibility: hidden;
    transition: 0.3s ease;
    opacity: 0;
    &.home {
        background: transparent;
    }
    &.active {
        opacity: 1;
        visibility: visible;
    }

    &-box {
        position: absolute;
        bottom: 4vh;
        left: 50%;
        transform: translateX(-50%);
        height: 22.8vh;
        max-height: 260px;
        width: auto;
        min-width: 59%;
        background: url("../../../assets/images/labo-guide-frame.png") no-repeat;
        background-size: 92% 100%;
        background-position: right center;

        display: flex;
        align-items: center;

        
        &-character {
            position: relative;
            height: 100%;
            animation: characterToTop 1s ease-in-out;
            @keyframes characterToTop {
                0% {
                    transform: translateY(20%);
                }
                100% {
                    transform: translateY(0);
                }
            }
            img {
                &:first-child {
                    max-height: 138%;
                    width: auto;
                    transform: translateY(-28.8%);
                }
                &:last-child {
                    position: absolute;
                    left: 5%;
                    bottom: -17%;
                    max-height: 56%;
                    width: auto;
                }
            }
            &.th {
                img {
                    &:first-child {
                        max-height: 150%;
                        transform: translate(-5%, -34.5%);
                    }
                    &:last-child {
                        left: 23%;
                        bottom: -12%;
                        max-height: 58%;
                        width: auto;
                    }
                }
            }
            &.home {
                img {
                    &:last-child {
                        left: 17%;
                    }
                }
            }
        }
        &-text {
            position: relative;
            padding: 0 35px;
            animation: zoomIn 1s ease-in-out;
            height: 100%;
            &.th {
                display: block;
                padding: 0 35px 0 0;
                p {
                    padding-top: 7%;
                    transform: translateX(-7%);
                }
            }

            &.home {
                justify-content: center;
                h4 {
                    display: none;
                }
            }

            p {
                // font-size: 2.45vh;
                font-size: 1.1vw;
                padding-top: 7%;
            }
            h4 {
                position: absolute;
                right: 35px;
                bottom: 10%;
                padding-top: 0.5rem;
                // font-size: 2.6vh;
                font-size: 1.3vw;
                text-align: right;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .guide-box {
        min-width: 90%;
        height: 15vw;
        &-text {
            p {
                padding-top: 3%;
                font-size: 1.7vw;
            }
            h4 {
                font-size: 2vw;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .guide-box {
        height: auto;
        max-height: unset;
        min-width: unset;
        min-height: 50vw;
        width: 75%;
        bottom: unset;
        left: 50%;
        top: 40%;
        transform: translateX(-50%);
        background: url("../../../assets/images/m-guide-frame.png") no-repeat;
        background-size: 100% 100%;
        &-text {
            p {
                padding-top: 3%;
                padding: 4% 0 20% 0;
                font-size: 1.7vw;
            }
            h4 {
                font-size: 2vw;
            }
        }
        &-character {
            position: absolute;
            top: -40%;
            left: 50%;
            height: 40%;
            transform: translateX(-50%);
            img {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                &:first-child {
                    max-height: 150%;
                    width: auto;
                    transform: translate(-50%,0);
                }
                &:last-child {
                    left: 0;
                    bottom: -17%;
                    max-height: 70%;
                }
            }
            &.labo {
                img {
                    &:first-child {
                        transform: translate(-40%,0);
                    }
                }
            }
            &.th {
                img {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                    &:first-child {
                        max-height: 150%;
                        width: auto;
                        transform: translate(-50%,0);
                    }
                    &:last-child {
                        left: 0;
                        bottom: -17%;
                        max-height: 70%;
                    }
                }
            }
        }
        &-text {
            padding: 20px 35px;
            &.th {
                display: block;
                padding: 20px 35px;
                p {
                    padding: 4% 0 20% 0;
                    transform: translateX(0);
                }
            }

            p {
                font-size: 3vw;
                padding: 4% 0 20% 0;
                text-align: center;
            }
            h4 {
                bottom: 10%;
                right: 50%;
                transform: translateX(50%);
                font-size: 4vw;
                text-align: center;
                white-space: nowrap;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .guide {
        &-box {
            min-height: unset;
            &-text {
                p {
                    font-size: 4vw;
                }
            }
        }
    }
}