@import "../../../../sass/variables";
.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateX(-5px);
    &-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0.5rem 0;
        &-label {
            font-size: 3.15vh;
            min-width: 24.5vh;
            text-align: right;
            white-space: nowrap;
            margin-right: 20px !important;
            animation: zoomIn 1s ease-in-out;
        }
        &-select {
            animation: zoomIn 1s ease-in-out;
            .ant-select-selector {
                align-items: center;
                padding: 0 1.5rem !important;
                background-color: #8129b0 !important;
                border: none;
                height: 4.6vh !important;
                min-width: 32.5vh;
                border-radius: 28px;
                border: none !important;
                .ant-select-selection-search-input {
                    height: 75% !important;
                    margin-top: 2% !important;
                    border-radius: unset;
                    cursor: pointer !important;
                }
                .ant-select-selection {
                    &-placeholder, &-item, &-search {
                        font-family: "Tungsten-Bold";
                        font-size: 3.15vh;
                    }
                    &-item, &-search {
                        color: $text-blue;
                    }
                    &-search {
                        padding: 0 1rem !important;
                    }
                }
            }
            .ant-select-arrow {
                inset-inline-end: 2vh;
                transform: translateY(-50%);
                img {
                    max-height: 2.2vh;
                    width: auto;
                    object-fit: cover;
                }
            }
            input {
                height: 4.6vh;
            }
        }
        
        input {
            font-family: "Tungsten-Bold";
            font-size: 3.15vh;
            padding: 0 1.5rem;
            height: 4.6vh; 
            background: #8129b0;
            color: $text-blue;
            border-radius: 28px;
            border: none;
            animation: zoomIn 1s ease-in-out;
        }
        &.mail {
            input {
                min-width: 39vh;

                &.error {
                    border: 3px solid #f23434;
                    color: #f23434;
                }
            }
        }
        &-id {
            font-size: 2.6vh;
            animation: zoomIn 1s ease-in-out;
            span {
                font-size: 3.15vh;
            }
            margin-left: 1.75vh !important;
        }
        &-otp {
            margin: 0 0.9vh;
            &:first-child {
                margin-left: 0;
            }
            &.error {
                input {
                    border: 3px solid #f23434;
                    color: #f23434;
                    width: calc(5.2vh - 6px) !important;
                    height: calc(5.2vh - 6px);
                }
            }
            input {
                width: 5.2vh !important;
                height: 5.2vh;
                padding: 0;
                min-width: unset;
                border-radius: 50%;
                outline: none;
            }
        }
    }
    &-warn {
        margin: 0.5vw 0;
        text-align: center;
        width: 100%;
        p {
            font-size: 1.1vw;
            color: #fff22a;
        }
    }
    &-confirm {
        cursor: pointer;
        background: unset;
        border: none;
        width: 100%;
        animation: zoomIn 1s ease-in-out;

        &:disabled {
            .form-confirm-btn {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        &-btn {
            min-height: 9vh;
            width: 42%;
            margin: 0 auto;
            background: url("../../../../assets/images/btn-form.png") no-repeat;
            background-size: 100% 100%;
            
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: auto;
                height: 3.5vh;
                padding-bottom: 5px;
            }
        }
        // img {
        //     transition: 0.3s ease;
        //     max-height: 10vh;
        //     width: auto;
        //     object-fit: cover;
        // }
    }
}

.ant-select-dropdown {
    z-index: 9999 !important;
    background-color: #59127f !important;
}
.rc-virtual-list-holder {
    // max-height: 180px !important;
}
.rc-virtual-list-scrollbar {
    background: #8129b0 !important;
    border-radius: 16px;
    margin: 12px 12px 0 0;
    &-thumb {
        height: 76px !important;
        background: $text-blue !important;
    }
}
.ant-select-item-option {
    width: 90%;
    padding: 5px 18px !important;
    &:hover {
        background-color: #8129b0 !important;
    }
    &-content {
        color: $text-yellow;
        font-family: "Tungsten-Bold";
        font-size: 3.15vh;
        line-height: 1.3;
    }
    &-selected {
        background-color: #8129b0 !important;
    }
}

@media only screen and (max-width: 992px) {
    .form {
        &-input {
            &-label {
                font-size: 3vw;
                min-width: 25vw;
                padding-bottom: 1vw;
            }
            &.select {
                .group {
                    flex-flow: unset;
                }
            }
        }
        &-warn {
            p {
                font-size: 2.5vw;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .form {
        transform: translateX(0);
        &-input {
            flex-direction: column;
            align-items: flex-start;
            margin: 0.8vw 0;
            &-label {
                font-size: 5vw;
                min-width: unset;
                text-align: left;
                margin-right: unset;
                padding-bottom: 1vw;
            }
            &.select {
                .group {
                    flex-flow: unset;
                }
            }
            &.serial {
                flex-direction: row;
                align-items: center;
            }
        }
        &-warn {
            width: 100%;
            margin: 0.8vw auto;
            max-width: 75vw;
            p {
                font-size: 4.7vw;
            }
        }
        &-confirm {
            &-btn {
                width: 50%;
                min-height: unset;
                padding: 4% 0;
                img {
                    height: auto;
                    width: 40%;
                }
            }
        }
    }
}