.home-guide {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    height: 40%;
    padding: 0 1rem;

    &-btn {
        cursor: pointer;
        transition: 0.4s ease;
        &:hover {
            transform: scale(1.03);
        }
        &.left {
            visibility: hidden;
            height: 110%;
            width: auto;
            animation: zoomIn 1s ease-in-out;
        }
        &.right {
            height: 100%;
            width: auto;
            object-fit: cover;
            &:hover {
                transform: scaleX(1.01);
            }
        }
    }
    &-mid {
        margin: 1% 3% 0 5%;
        height: 80%;
        max-height: 260px;
        background: url("../../../../assets/images/labo-guide-frame.png") no-repeat;
        background-size: 100% 100%;
        max-width: 70%;

        display: flex;
        align-items: center;

        &-character {
            position: relative;
            height: 100%;
            img {
                &:first-child {
                    max-height: 134%;
                    width: auto;
                    object-fit: cover;
                    transform: translate(-12%, -26.8%);
                    animation: CharToTop 1s ease-in-out;
                }
                &:last-child {
                    position: absolute;
                    left: 5%;
                    bottom: -12%;
                    max-height: 56%;
                    width: auto;
                    object-fit: cover;
                    animation: characterToTop 1s ease-in-out;
                }
            }
            @keyframes CharToTop {
                0% {
                    opacity: 0;
                    transform: translate(-12%, -12.8%);
                }
                100% {
                    opacity: 1;
                    transform: translate(-12%, -26.8%);
                }
            }
        }
        &-text {
            padding: 0 5% 0 0;
            animation: zoomIn 1s ease-in-out;
            p {
                font-size: 1.3vw;
                line-height: 1.3;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {
    .home-guide {
        height: 30%;
        &-mid {
            display: none;
        }
    }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
    .home-guide {
        &-btn {
            &.left {
                max-width: 50%;
            }
        }
    }
}