.labor {
    position: relative;
    height: 100%;
    background: url("../../assets/images/labor-bg.jpg") no-repeat;
    background-size: 100% 100%;

    h2 {
        padding-top: 3.8vh;
        font-size: 5.7vh;
        text-align: center;
        height: 7.5vh;
        animation: zoomIn 1s ease-in-out;
    }

    &-container {
        height: calc(100% - 7.5vh - 4vh);
    }

    &-picker {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        margin: 1% 0;
    }

    &-game {
        position: relative;
        &-icon {
            position: absolute;
            z-index: 1;
            left: -40%;
            bottom: 15%;
            max-height:30%;
            animation: zoomIn 1s ease-in-out;
            &.right {
                display: none;
            }
        }
    }

    &-icon {
        position: relative;
        &-img {
            position: absolute;
            animation: zoomIn 1s ease-in-out;
            &.top {
                top: -5%;
                right: 30%;
                max-height: 35%;
            }
            &.bot {
                bottom: 15%;
                left: 15%;
                max-height: 45%;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .labor {
        h2 {
            padding-top: 3.8vh;
            font-size: 4.7vw;
            text-align: center;
            height: 7.5vh;
            animation: zoomIn 1s ease-in-out;
        }
    
        &-container {
            height: calc(100% - 7.5vh - 4vh);
        }
    
        &-picker {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
            margin: 1% 0;
        }
    
        &-game {
            position: relative;
            &-icon {
                position: absolute;
                z-index: 1;
                left: -40%;
                bottom: 15%;
                max-height:30%;
                animation: zoomIn 1s ease-in-out;
            }
        }
    
        &-icon {
            position: relative;
            &-img {
                position: absolute;
                animation: zoomIn 1s ease-in-out;
                &.top {
                    top: -5%;
                    right: 30%;
                    max-height: 35%;
                }
                &.bot {
                    bottom: 15%;
                    left: 15%;
                    max-height: 45%;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .labor {
        background-size: cover;
        background-position: top center;
        &-game {
            &-icon {
                max-height: 20%;
                left: -30%;
                bottom: 20%;
            }
        }
    }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
    .labor {
        h2 {
            font-size: 6.7vw;
            height: 30vw;
            padding-top: 0;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 3%;
                width: 12%;
                height: 62%;
                background: url("../../assets/images/labo_ic_3.png");
                background-size: 100% 100%;
            }
        }
        &-container {
            height: calc(100% - 30vw);
            .ant-row {
                display: flex;
                flex-direction: column;
                flex-flow: column-reverse;
                .ant-col-xs-24 {
                    flex: unset !important;
                }
            }
        }
        &-picker {
            position: relative;
            z-index: 1;
            flex-direction: row;
            height: auto;
            overflow: hidden;
            margin: 0;
        }
        &-game {
            &-icon {
                max-height: 12%;
                left: 4%;
                bottom: unset;
                top: 33%;
                &.right {
                    display: block;
                    left: unset;
                    right: 0%;
                    top: unset;
                    bottom: 25%;
                    max-height: 18%;
                }
            }
        }
    }
}