.points {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    cursor: pointer;
    visibility: hidden;
    transition: 0.5s ease;
    opacity: 0;
    &.active {
        opacity: 1;
        visibility: visible;
    }

    display: flex;
    align-items: center;
    &-box {
        position: relative;
        width: auto;
        padding: 0 10%;
        height: 50%;
        margin: 0 auto;
        background: url("../../../assets/images/point-box.png");
        background-size: 100% 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            font-size: 4.2vh;
            text-align: center;
            white-space: nowrap;
            padding-bottom: 2vh;
            span {
                color: #ff82d4;
            }
        }
        .btn {
            max-height: 10vh;
            width: auto;
        }
        .close {
            position: absolute;
            top: 0%;
            right: 0%;
            height: 23%;
            width: auto;
        }
    }
}

@media only screen and (max-width: 992px) {
    .points {
        &-box {
            height: 30%;
            p {
                font-size: 4vw;
            }
            .btn {
                width: 60%;
            }
        }
    }    
}

@media only screen and (max-width: 575px) {
    .points {
        &-box {
            height: 25%;
            padding: 5% 10%;
            p {
                font-size: 5vw;
            }
            .btn {
                width: 50%;
                max-height: 12vw;
            }
        }
    }    
}