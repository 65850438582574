@import "../sass/variables";

.layout {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    &-head {
        position: relative;
        z-index: 101;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
        // height: 182px;
        height: 16vh;
        img {
            max-height: 77%;
            width: auto;
            object-fit: cover;
            cursor: pointer;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            height: 10px;
            width: 100%;
            background: url("../assets/images/line-head.png") no-repeat;
            background-size: 100% 100%;
        }
        &-title {
            position: absolute;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
            font-family: "Urzeit-Italic";
            // font-size: 36px;
            font-size: 3.15vh;
            color: $text-white;
            text-align: right;
            .br-mob {
                display: none;
            }
            a {
                color: $text-blue;
                text-decoration: underline;
                cursor: pointer;
            }
            span {
                color: #3ffec3;
            }
            &.welcome {
                cursor: pointer;
            }
        }
    }
    &-body {
        position: relative;
        z-index: 100;
        // height: calc(100vh - 182px - 62px);
        height: 73.8vh;
        &-back {
            position: absolute;
            left: 0;
            top: 30px;
            z-index: 99;
            cursor: pointer;
            img {
                max-height: 10.3vh;
                width: auto;
                object-fit: cover;
            }
        }
    }
    &-bottom {
        position: relative;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        // height: 62px;
        height: 10.2vh;
        background: #000;
        &-text {
            width: 100%;
            height: 72%;
            padding: 0 1.5% 0 2%;

            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                color: $text-white;
                font-size: 22px;
                // padding-top: 2.2%;
                text-transform: uppercase;
                white-space: nowrap;
            }
            &-group {
                display: flex;
                align-items: center;
                a {
                    font-size: 20px;
                    font-weight: bold;
                    text-decoration: none;
                    white-space: nowrap;
                    &:first-child {
                        // margin-right: 50px !important;
                    }
                }
            }
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            // height: 54px;
            height: 28%;
            width: 100%;
            background: url("../assets/images/line-head.png") no-repeat;
            background-size: 100% 100%;
        }
    }
}
@media only screen and (max-width: 1200px) {
    .layout {
        &-head {
            &-title {
                font-size: 1.8vw;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .layout {
        &-head {
            height: 13vh;
            img {
                width: 35%;
                height: auto;
            }
        }
        &-body {
            height: 76.8vh;
            &-back {
                max-width: 22%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        &-bottom {
            &-text {
                p {
                    font-size: 20px;
                }
                &-group {
                    a {
                        font-size: 16px;
                        &:first-child {
                            // margin-right: 30px !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .layout {
        &-head {
            height: 8.2vh;
            img {
                width: 35%;
                height: auto;
            }
            &-title {
                right: 0.3rem;
                font-size: 2.4vw;
                span {
                    font-size: 2.8vw;
                }
                .br-mob {
                    display: block;
                }
            }
        }
        &-body {
            height: 83.8vh;
            &-back {
                max-width: 30%;
            }
        }
        &-bottom {
            height: 8vh;
            &-text {
                padding: 0 4%;
                p {
                    font-size: 12px;
                }
                &-group {
                    a {
                        font-size: 2.1vw;
                        &:first-child {
                            // margin-right: 12px !important;
                        }
                    }
                }
            }
        }
    }
}